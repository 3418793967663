.common-media-dialog {
    height: 100%;
    display: flex;
    .middle {
        display: flex;
        flex-direction: column;
        flex: 1;
        .content {
            flex: 1;
            display: block;
            overflow: auto;
            position: relative;
            .image-item {
                width: 120px;
                height: 120px;
                display: flex;
                margin-top: 12px;
                margin-right: 12px;
                position: relative;
                float: left;
                background-color: #f5f5f5;
                border: 2px solid #efefef;
                .checkbox {
                    position: absolute;
                    right: -2px;
                    top: -4px;
                    display: none;
                }
                &.active {
                    border: 2px solid #1677ff;
                    .checkbox {
                        display: initial;
                    }
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                
            }
        }
    }
    .right {
        width: 280px;
        display: flex;
        flex-direction: column;
        .content {
            overflow: auto;
            flex: 1;
            background-color: #f5f5f5;
        }
        .bottom {
            padding-top: 12px;
            display: flex;
            flex-direction: row-reverse;
        }
    }
}