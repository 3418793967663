.header {
  background-color: #fff;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
}
.title {
  font-size: 22px;
  color: #0068b6;
  font-weight: bold;
}
.right {
}
.userInfo {
  display: flex;
  align-items: center;
}
.avatar {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  border: 1px solid #dedede;
  display: flex;
}
.userName {
  height: 24px;
}
