.sideMenu {
  background-color: #1c85bf;
  height: 100%;
  display: flex;
  flex-direction: column;
  :global(.ant-layout-sider-children) {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }
}


.logo {
  width: 134px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.menu {
  background-color: #1c85bf;
  color: #fff;
  flex: 1;
  overflow: auto;
  

  :global(.ant-menu-item) {
    border-radius: 0px;
    margin: 0px;
    width: 100%;
  }

  :global(.ant-menu-item-selected) {
    background-color: #0e5a90;
    color: #74d9ff;
  }
  :global(.ant-menu-submenu-title) {
    margin: 0px;
    border-radius: 0px;
    width: 100%;
  }
  // :global(.ant-menu-submenu-title:hover) {
  //   margin: 0px;
    
  // }
  :global(.ant-menu-submenu-selected .ant-menu-submenu-title) {
    color: #74d9ff;
    // background-color: #0e5a90;
    border-radius: 0px;
    width: 100%;
  }
}
